.footerMenu {
  display: none;
}

@media screen and (max-width: 993px) {
  .footerMenu {
    display: flex;
    // position: fixed;
    position: sticky;
    bottom: 0;

    display: flex;
    padding: 0 1rem;
    justify-content: space-between;
    align-items: center;
    gap: 10px;

    background-color: var(--background-footer);
    width: 100%;
    height: 7rem;
    padding: 2rem;

    li {
      list-style: none;
      width: 100%;

      .dGrid {
        display: flex;
        flex-direction: column;
        align-items: center;
        font-size: 14px;
        transition: all 0.4s;

        span {
          transition: all 0.4s;
          color: var(--white);

          svg {
            color: var(--color-button);
            font-size: 2rem;
          }

          img {
            height: 3rem;
            width: auto;
          }
        }

        .texta {
          margin-top: -2px;
        }
      }
    }

    .headerBartwo {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      position: relative;
      left: 0;
      right: 0;
      bottom: 1rem;
      background-color: var(--background-color-button);

      width: 60px;
      height: 60px;
      border-radius: 50%;
      //border: 2px dashed var(--yellow-color);

      .numItems {
        position: absolute;
        display: flex;
        justify-content: center;
        top: -0.5rem;
        right: -0.5rem;
        color: var(--black);
        background-color: var(--yellow-color);
        border-radius: 2rem;
        padding: 0 0.5rem;
      }
      .middleButton {
        min-width: 6rem;
      }

      .cros {
        svg {
          color: var(--yellow-color);
          font-size: 2rem;
        }
      }
    }
  }
}
