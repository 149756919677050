.betSlip {
  // margin-top: 1rem;
  position: sticky;
  width: 100%;
  //top: 140px;
  //max-height: calc(100vh - 6.8rem);
  height: 100%;
  overflow-y: auto;
  overflow-x: hidden;

  .content {
    height: 100%;

    .informationUser {
      background: var(--secondary-color);
      border-radius: 0.5rem;

      margin-bottom: 1rem;
      padding: 1rem;

      .top {
        display: flex;
        align-items: center;
        justify-content: space-between;
        height: 100%;

        .level {
          display: flex;
          flex-direction: column;
          align-items: center;
          gap: 1rem;
          background: var(--header);

          border-radius: 0.5rem;

          padding: 1rem 3rem;

          img {
            max-width: 5rem;
            max-height: 8rem;
            object-fit: cover;
          }

          p {
            color: var(--white);
            font-size: 1.3rem;
          }
        }

        .user {
          display: flex;
          flex-direction: column;
          align-items: flex-end;
          justify-content: space-between;
          gap: 3.5rem;

          height: 100%;

          .information {
            display: flex;
            flex-direction: column;
            align-items: flex-end;
            gap: 0.5rem;

            span {
              color: var(--textcolor);
              font-size: 1.2rem;
            }

            .id {
              color: var(--white);
              font-size: 1.3rem;
            }

            .days {
              color: var(--button-two);
              font-size: 1.3rem;
            }
          }
        }
      }

      .divisor {
        border-bottom: 1px solid var(--multiborder);
        margin: 1rem 0;
      }

      .informationsMonney {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: 1rem;

        .inputInformation {
          display: flex;
          align-items: center;
          justify-content: space-between;

          width: 100%;

          .inputItem {
            display: flex;
            align-items: center;
            gap: 0.5rem;

            label {
              color: var(--white);
              font-size: 1.2rem;
            }

            img {
              width: 24px;
              height: auto;
            }
          }

          .value {
            color: var(--active-color);
            font-size: 1.2rem;
          }
        }
      }

      .buttons {
        display: grid;
        grid-template-columns: repeat(5, 1fr);
        gap: 0.5rem;

        margin-top: 2rem;

        button {
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          gap: 0.5rem;

          background-color: var(--buttons-background-color);
          border: 1px solid transparent;
          border-radius: 0.5rem;

          color: var(--white);
          cursor: pointer;

          font-size: var(--font-size-md);

          padding: 0.6rem 0.4rem;
          transition: all 0.35s ease;

          img {
            width: 15px;
            height: auto;
            object-fit: cover;
          }

          &:hover {
            border: 1px solid var(--yellow-color);
          }
        }
      }
    }

    .fieldApostasy {
      background: var(--betslip-background);
      border-radius: 0.5rem;

      margin-bottom: 1rem;
      padding: 1rem;

      .top {
        display: flex;
        align-items: center;
        justify-content: space-between;

        h1 {
          color: var(--textcolor);
          font-size: 1.3rem;
          font-weight: var(--font-bold-light);
        }

        button {
          background-color: transparent;
          border: 0;

          color: var(--textcolor);
          cursor: pointer;

          font-size: 1.3rem;
          font-weight: var(--font-bold-light);
        }
      }

      .hide {
        display: none;
      }

      .text {
        color: var(--white);
        font-size: 1.4rem;

        padding: 1rem 0;
        text-align: center;
      }

      .bonusData {
        display: flex;
        color: var(--white);

        .league {
          font-size: 1.2rem;
          width: 80%;
        }

        .buttonContainer {
          display: flex;
          flex-direction: row-reverse;
          align-items: center;

          width: 20%;
          text-align: right;

          .closeButton {
            cursor: pointer;
          }
        }
      }

      .marketData {
        display: flex;
        margin: 6px 0;

        .market {
          color: var(--active-two);
          font-size: 1.2rem;

          width: 70%;
        }

        .price {
          color: var(--price-color);
          font-size: 1.2rem;

          width: 30%;
          text-align: right;
        }
      }
    }

    .fieldsInput {
      background: var(--secondary-color);
      border-radius: 0.5rem;

      margin: 1rem 0;
      padding: 1rem;

      .top {
        display: flex;
        align-items: center;
        justify-content: space-between;

        h1 {
          color: var(--textcolor);
          font-size: 1.3rem;
          font-weight: var(--font-bold-light);
        }

        .updateInput {
          display: flex;
          align-items: center;
          gap: 0.8rem;

          svg {
            color: var(--white);
            cursor: pointer;
            font-size: 2rem;
          }

          input {
            background-color: transparent;
            border: 1px solid var(--border-color-input);
            border-radius: 0.2rem;

            color: var(--white);
            font-size: 1.3rem;
            padding: 0.5rem;
          }

          input:focus {
            outline: transparent;
            border: 1px solid var(--button-two);
          }
        }
      }

      .buttonValues {
        display: grid;
        grid-template-columns: auto auto auto;
        align-items: center;
        gap: 0.5rem;
        margin-top: 2rem;

        .number {
          background-color: var(--buttons-background-color);
          border: 1px solid transparent;
          border-radius: 0.5rem;
          // font-size: 8px;

          color: var(--white);
          cursor: pointer;

          font-size: 1.2rem;
          font-weight: 400;

          padding: 0.8rem 1rem;

          transition: all 0.35s ease;
          width: 100%;

          &:hover {
            background-color: var(--yellow-color);
            color: var(--button-color);

            svg {
              color: var(--white);
            }
          }
        }
      }

      .datasValues {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: 1rem;

        margin: 2rem 0;

        .data {
          display: flex;
          align-items: center;
          justify-content: space-between;

          border-bottom: 1px solid var(--multiborder);
          padding-bottom: 0.6rem;
          width: 100%;

          .title {
            color: var(--white);
            font-size: 1.2rem;
          }

          .value {
            color: var(--white);
            font-size: 1.2rem;
          }
        }
      }

      .buttonData {
        position: relative;
        z-index: 1;

        background-color: var(--yellow-color);
        border: 0;
        border-radius: 0.5rem;

        color: var(--button-color);
        cursor: pointer;

        font-size: 1.3rem;
        font-weight: var(--font-bold-light);

        overflow: hidden;
        padding: 1rem 2rem;

        text-transform: capitalize;
        transition: all 0.3s ease;
        text-align: center;
        width: 100%;

        span {
          height: 14px !important;
          width: 14px !important;
          color: black;
        }
        // &::before {
        //     position: absolute;
        //     content: "";
        //     top: 0;
        //     left: 0;
        //     height: 100%;
        //     width: 0px;
        //     transition: all 0.4s;
        // }

        // &:hover::before {
        //     width: 100%;
        // }

        &:hover {
          background: var(--primary-color);
          color: var(--white);
        }

        // span {
        //     z-index: 1;
        //     position: relative;
        //     transition: all 0.4s;
        //     display: block;
        // }

        // &::before {
        //     background: linear-gradient(114.26deg, #f3486a 13.41%, #f3486a 87.24%);
        // }
      }
    }

    .imagesLink {
      display: flex;
      flex-direction: column;
      gap: 0.5rem;

      margin-bottom: 0.5rem;

      .imageLink {
        width: 100%;
        height: 100%;
        object-fit: cover;
        cursor: pointer;
      }
    }

    // h3 {
    //     background: var(--header);
    //     padding: 10px 10px 12px;
    //     color: var(--white);
    //     font-weight: var(--font-bold-light);
    // }

    // .tabsBetSlip {
    //     background: var(--slidebox);
    //     .titleTabsSlip {
    //         display: flex;
    //         align-items: center;
    //         justify-content: space-between;
    //         gap: 1.5rem;

    //         border-bottom: 1px solid var(--leftpreborder);

    //         padding: 1rem 1rem 0;
    //         width: 100%;

    //         button {
    //             background: transparent;
    //             border: 1px solid transparent;
    //             border-radius: 0.4rem;

    //             cursor: pointer;
    //             color: var(--white-color);

    //             font-weight: var(--font-bold-light);
    //             font-size: 1.4rem;

    //             line-height: 2.2rem;
    //             padding: 3px 10px 1px;

    //             transition: initial;

    //             &:hover {
    //                 background: transparent;
    //                 // color: var(--dark-blue);
    //             }

    //             &[aria-selected="true"] {
    //                 border-bottom: 1px solid var(--active-color);
    //                 border-radius: 0;
    //                 color: var(--active-color);
    //             }

    //             &:focus {
    //                 box-shadow: none;
    //             }
    //         }
    //     }
    // }
  }
}

@media (max-width: 1500px) {
  .betSlip {
    .content {
      // max-height: calc(100vh - 200px);
      overflow-x: auto;

      .informationUser {
        .informationsMonney {
          .inputInformation {
            .inputItem {
              label {
                font-size: 1.1rem;
              }
            }

            .value {
              font-size: 1.1rem;
            }
          }
        }
      }

      .fieldsInput {
        .top {
          gap: 2rem;

          h1 {
            font-size: 1.1rem;
          }

          .updateInput {
            justify-content: flex-end;

            input {
              width: 80%;
            }
          }
        }

        .buttonValues {
          // display: flex;
          // align-items: center;
          // justify-content: space-between;
          // gap: 0.5rem;
          // margin-top: 2rem;
          // display: grid;
          grid-template-columns: auto auto auto;
          align-items: center;
          gap: 0.5rem;
          margin-top: 2rem;

          .number {
            background: var(--buttons-background-color);
            border: 1px solid transparent;
            border-radius: 0.5rem;

            color: var(--white);
            cursor: pointer;

            font-size: 0.7rem;
            font-weight: 100;

            padding: 0.8rem;

            transition: all 0.35s ease;
            width: 100%;

            &:hover {
              background: var(--yellow-color);
              border: 1px solid transparent;

              color: var(--button-color);
            }
          }
        }

        .datasValues {
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          gap: 1rem;

          margin: 2rem 0;

          .data {
            display: flex;
            align-items: center;
            justify-content: space-between;

            border-bottom: 1px solid var(--multiborder);
            padding-bottom: 0.6rem;
            width: 100%;

            .title {
              color: var(--white);
              font-size: 1.2rem;
            }

            .value {
              color: var(--white);
              font-size: 1.2rem;
            }
          }
        }

        .buttonData {
          position: relative;
          z-index: 1;

          background-color: var(--yellow-color);
          border: 0;
          border-radius: 0.5rem;

          color: var(--button-color);
          cursor: pointer;

          font-size: 1.3rem;
          font-weight: var(--font-bold-light);

          overflow: hidden;
          padding: 1rem 2rem;

          text-transform: capitalize;
          transition: all 0.3s;
          text-align: center;
          width: 100%;

          // &::before {
          //     position: absolute;
          //     content: "";
          //     top: 0;
          //     left: 0;
          //     height: 100%;
          //     width: 0px;
          //     transition: all 0.4s;
          // }

          // &:hover::before {
          //     width: 100%;
          // }

          span {
            z-index: 1;
            position: relative;
            transition: all 0.4s;
            display: block;
          }

          // &::before {
          //     background: linear-gradient(114.26deg, #f3486a 13.41%, #f3486a 87.24%);
          // }
        }
      }

      .imagesLink {
        display: flex;
        flex-direction: column;
        gap: 0.5rem;

        .imageLink {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }
    }
  }
}

@media only screen and (max-width: 1024px) {
  .betSlip {
    display: none;
  }

  .betSlipMobile {
    position: fixed;
    top: 0;
    left: 0;
    height: 100vh;
    width: 100%;

    background-color: var(--body-color);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: top;
    // z-index: 20;
    z-index: 99998;
    overflow-x: auto;

    .informationsMonney {
      display: flex;
      align-items: flex-start;
      gap: 2rem;

      padding: 0 1rem;

      .inputInformation {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-between;

        background-color: var(--secondary-color);
        border-radius: 0.5rem;

        margin: 2rem 0 1rem 0;
        padding: 1rem;
        width: 100%;

        .inputItem {
          display: flex;
          align-items: center;
          justify-content: center;
          gap: 0.5rem;

          label {
            color: var(--white);
            font-size: 1.2rem;
          }

          img {
            width: 24px;
            height: auto;
          }
        }

        .value {
          color: var(--active-color);
          font-size: 1.3rem;
        }
      }
    }

    .container {
      position: relative;

      display: flex;
      flex-direction: column;

      // padding: 3rem;
      width: 100%;
      //height: 80%;
      height: 90%;

      .iconClose {
        position: relative;

        svg {
          position: absolute;
          top: -0.5rem;
          right: 0.5rem;

          color: white;
          font-size: 3rem;
        }
      }

      .content {
        overflow-x: auto;

        .informationUser {
          background: var(--slidebox);
          border-radius: 0.5rem;

          padding: 2rem;

          .top {
            display: flex;
            align-items: center;
            justify-content: space-between;
            height: 100%;

            .level {
              display: flex;
              flex-direction: column;
              align-items: center;
              gap: 1rem;

              background: var(--header);
              border-radius: 0.5rem;

              padding: 1rem 3rem;

              img {
                max-width: 6rem;
                max-height: 8rem;
                object-fit: cover;
              }

              p {
                color: var(--white);
                font-size: 1.3rem;
              }
            }

            .user {
              display: flex;
              flex-direction: column;
              align-items: flex-end;
              justify-content: space-between;
              gap: 3.5rem;

              height: 100%;

              .information {
                display: flex;
                flex-direction: column;
                align-items: flex-end;
                gap: 0.5rem;

                span {
                  color: var(--textcolor);
                  font-size: 1.2rem;
                }

                .id {
                  color: var(--white);
                  font-size: 1.3rem;
                }

                .days {
                  color: var(--button-two);
                  font-size: 1.3rem;
                }
              }
            }
          }

          .divisor {
            border-bottom: 1px solid var(--multiborder);
            margin: 1rem 0;
          }

          .informationsMonney {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            gap: 1rem;

            .inputInformation {
              display: flex;
              align-items: center;
              justify-content: space-between;

              width: 100%;

              .inputItem {
                display: flex;
                align-items: center;
                gap: 0.5rem;

                label {
                  color: var(--white);
                  font-size: 1.2rem;
                }

                img {
                  width: 24px;
                  height: auto;
                }
              }

              .value {
                color: var(--active-color);
                font-size: 1.2rem;
              }
            }
          }

          .buttons {
            display: grid;
            grid-template-columns: repeat(5, 1fr);
            gap: 0.5rem;

            margin-top: 2rem;

            button {
              display: flex;
              flex-direction: column;
              align-items: center;
              justify-content: center;
              gap: 0.5rem;

              background-color: transparent;
              border: 1px solid var(--social-box);
              border-radius: 0.5rem;

              color: var(--white);
              cursor: pointer;

              font-size: 1rem;

              padding: 0.6rem;
              transition: all 0.35s ease;

              img {
                width: 20px;
                height: auto;
                object-fit: cover;
              }

              &:hover {
                background: var(--pointbox);
                border: 1px solid transparent;
              }
            }
          }
        }

        .fieldApostasy {
          background: var(--slidebox);
          border-radius: 0.5rem;

          margin: 1rem 0;
          padding: 1rem;

          .top {
            display: flex;
            align-items: center;
            justify-content: space-between;

            h1 {
              color: var(--textcolor);
              font-size: 1.3rem;
              font-weight: var(--font-bold-light);
            }

            button {
              background-color: transparent;
              border: 0;

              color: var(--textcolor);
              cursor: pointer;

              font-size: 1.3rem;
              font-weight: var(--font-bold-light);
            }
          }

          .text {
            color: var(--white);
            font-size: 1.4rem;

            padding: 4rem 0 2rem 0;
            text-align: center;
          }

          .bonusData {
            display: flex;
            color: var(--white);

            .league {
              font-size: 1.2rem;
              width: 80%;
            }

            .buttonContainer {
              display: flex;
              flex-direction: row-reverse;
              align-items: center;

              width: 20%;
              text-align: right;

              .closeButton {
                cursor: pointer;
              }
            }
          }

          .marketData {
            display: flex;
            margin: 6px 0;

            .market {
              color: var(--active-two);
              font-size: 1.2rem;

              width: 70%;
            }

            .price {
              color: var(--price-color);
              font-size: 1.2rem;

              width: 30%;
              text-align: right;
            }
          }
        }

        .fieldsInput {
          background: var(--slidebox);
          border-radius: 0.5rem;

          margin: 1rem 0;
          padding: 1rem;

          .top {
            display: flex;
            align-items: center;
            justify-content: space-between;

            h1 {
              color: var(--textcolor);
              font-size: 1.3rem;
              font-weight: var(--font-bold-light);
            }

            .updateInput {
              display: flex;
              align-items: center;
              gap: 0.8rem;

              svg {
                color: var(--white);
                cursor: pointer;
                font-size: 2rem;
              }

              input {
                background-color: transparent;
                border: 1px solid var(--border-color-input);
                border-radius: 0.2rem;

                color: var(--white);
                font-size: 1.3rem;
                padding: 0.5rem;
              }

              input:focus {
                outline: transparent;
                border: 1px solid var(--button-two);
              }
            }
          }

          .buttonValues {
            // display: flex;
            // align-items: center;
            // justify-content: space-between;
            // gap: 0.5rem;
            // margin-top: 2rem;
            display: grid;
            grid-template-columns: auto auto auto;
            align-items: center;
            gap: 0.5rem;
            margin-top: 2rem;

            .number {
              background: var(--secondary-color);
              border: 0;
              border-radius: 0.5rem;

              color: var(--white);
              cursor: pointer;

              font-size: 1rem;
              font-weight: 400;

              padding: 0.8rem 1rem;

              transition: all 0.4 ease;
              width: 100%;

              &:hover {
                background-color: var(--button-two);

                svg {
                  color: var(--white);
                }
              }
            }
          }

          .datasValues {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            gap: 1rem;

            margin: 2rem 0;

            .data {
              display: flex;
              align-items: center;
              justify-content: space-between;

              border-bottom: 1px solid var(--multiborder);
              padding-bottom: 0.6rem;
              width: 100%;

              .title {
                color: var(--white);
                font-size: 1.2rem;
              }

              .value {
                color: var(--white);
                font-size: 1.2rem;
              }
            }
          }
          .mobileSlipeBetButtons {
            display: flex;
            gap: 1rem;

            .buttonData {
              position: relative;
              z-index: 1;

              background-color: var(--yellow-color);
              border: 0;
              border-radius: 0.5rem;

              color: var(--button-color);
              cursor: pointer;

              font-size: 1.3rem;
              font-weight: var(--font-bold-light);

              overflow: hidden;
              padding: 1rem 2rem;

              text-transform: capitalize;
              transition: all 0.3s;
              text-align: center;
              width: 100%;

              &::before {
                position: absolute;
                content: "";
                top: 0;
                left: 0;
                height: 100%;
                width: 0px;
                transition: all 0.4s;
              }

              &:hover::before {
                width: 100%;
              }

              span {
                z-index: 1;
                position: relative;
                transition: all 0.4s;
                display: block;
              }

              // &::before {
              //   background: linear-gradient(
              //     114.26deg,
              //     #f3486a 13.41%,
              //     #f3486a 87.24%
              //   );
              // }
            }

            .clear {
              background: var(--secondary-color);
              color: var(--white);
            }
          }
        }

        .imgContainer {
          width: 100%;
          display: flex;
          flex-direction: column;
          gap: 1rem;
          // >img{
          //   width: 100%;
          // }
        }
        // h3 {
        //     background: var(--header);
        //     padding: 10px 10px 12px;
        //     color: var(--white);
        //     font-weight: var(--font-bold-light);
        // }

        // .tabsBetSlip {
        //     background: var(--slidebox);
        //     .titleTabsSlip {
        //         display: flex;
        //         align-items: center;
        //         justify-content: space-between;
        //         gap: 1.5rem;

        //         border-bottom: 1px solid var(--leftpreborder);

        //         padding: 1rem 1rem 0;
        //         width: 100%;

        //         button {
        //             background: transparent;
        //             border: 1px solid transparent;
        //             border-radius: 0.4rem;

        //             cursor: pointer;
        //             color: var(--white-color);

        //             font-weight: var(--font-bold-light);
        //             font-size: 1.4rem;

        //             line-height: 2.2rem;
        //             padding: 3px 10px 1px;

        //             transition: initial;

        //             &:hover {
        //                 background: transparent;
        //                 // color: var(--dark-blue);
        //             }

        //             &[aria-selected="true"] {
        //                 border-bottom: 1px solid var(--active-color);
        //                 border-radius: 0;
        //                 color: var(--active-color);
        //             }

        //             &:focus {
        //                 box-shadow: none;
        //             }
        //         }
        //     }
        // }
      }
    }
  }
}
