.navigationSportsTwo {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: var(--navigation-sports-two-menu-gap, 2rem);

  border-right: 1px solid var(--color-red);

  height: 100%;
  width: 100%;
  // padding: 1.5rem 2rem 0 2rem;
  padding: 2rem 2rem 0 2rem;
  overflow-y: auto;

  .boxNavigation {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 1rem;

    border: 1px solid var(--color-red);
    border-radius: 1rem;

    padding: 1rem;
    width: 100%;

    p {
      display: grid;
      grid-template-columns: 35px 1fr;
      align-items: center;
      gap: 0.3rem;

      border-bottom: 1px solid var(--color-red);

      color: var(--white);
      cursor: pointer;

      font-size: 1.8rem;

      line-height: 4.5rem;
      width: 100%;
      transition: all 0.35s ease;

      img {
        max-width: 3rem;
        max-height: 3rem;
        object-fit: cover;
      }

      &:hover {
        color: var(--color-red);
      }
    }

    p:last-child {
      border-bottom: initial;
    }
  }

  .navigationGrid {
    display: grid;
    grid-template-columns: 33% 33% 33%;
    gap: 0.2rem;

    .subMenuGridItem {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      background: var(--secondary-color);
      padding: 1rem 0 1rem 1rem;
      cursor: pointer;
      font-size: 1.1rem;

      img {
        height: 1.5rem;
        width: 1.5rem;
      }

      .titleActive {
        color: var(--yellow-color);
      }

      &:not(.firstRow):hover {
        background: var(--primary-color);
      }
    }

    .firstRow {
      background-color: var(--yellow-color);
      background-image: var(--navigation-sports-gradient);

      &:hover {
        opacity: 0.8;
      }
    }
  }

  // > :last-child {
  //   border-bottom: 1px solid white;
  // }
}

.alternatePadding{
  padding: 0 2rem;
}

@media (max-width: 1600px) {
  .navigationSportsTwo {
    // max-height: calc(100vh - 200px);
    overflow-y: auto;
  }
}

@media only screen and (max-width: 1024px) {
  .navigationSportsTwo {
    display: none;
  }

  .navigationSportsTwoMobile {
    position: fixed;
    top: 0;
    left: 0;
    height: 100vh;
    width: 100%;

    background-color: var(--body-color);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    z-index: 100000;
    overflow-x: auto;

    .container {
      position: relative;

      display: flex;
      flex-direction: column;

      // padding: 3rem;
      width: 100%;
      //   height: 80%;
      height: 100%;

      .content {
        overflow-y: auto;
        padding-bottom: 28rem;

        .child {
          //background-color: red;
        }
      }
    }
  }
}
